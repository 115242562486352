import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding":"3em","padding-bottom":"0px"} }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_language_switcher = _resolveComponent("language-switcher")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_language_switcher)
          ]),
          _createElementVNode("div", {
            class: "mycontent",
            innerHTML: _ctx.t('imprinttext')
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}