import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { href: "https://www.openstreetmap.org/#map=19/47.6916/9.18709" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { style: {"font-weight":"bold"} }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { style: {"font-weight":"bold"} }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_language_switcher = _resolveComponent("language-switcher")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_menu, {
      side: "start",
      "menu-id": "nav",
      "content-id": "main"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Navigation")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navs, (n) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: n.goto,
                    class: "nav-item",
                    onClick: ($event: any) => (_ctx.scrollToEl(n.goto))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: n.icon,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.t(n.text)), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_page, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, { translucent: true }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                (_ctx.screen.width < _ctx.breakpoint)
                  ? (_openBlock(), _createBlock(_component_ion_buttons, {
                      key: 0,
                      slot: "start"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openMenu()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              icon: _ctx.menu
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_title, {
                  class: "title",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollToTop()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('maintitle')), 1)
                  ]),
                  _: 1
                }),
                (_ctx.screen.width >= _ctx.breakpoint)
                  ? (_openBlock(), _createBlock(_component_ion_buttons, {
                      key: 1,
                      slot: "primary"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navs, (n) => {
                          return (_openBlock(), _createBlock(_component_ion_button, {
                            key: n.goto,
                            onClick: ($event: any) => (_ctx.scrollToEl(n.goto))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t(n.text)) + " ", 1),
                              _createVNode(_component_ion_icon, {
                                slot: "start",
                                icon: n.icon
                              }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_buttons, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_language_switcher)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, {
          id: "main",
          ref: "content",
          fullpage: true,
          "scroll-events": true,
          onIonScroll: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getScrollPos($event.detail.scrollTop)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card, { id: "vcc" }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  style: {"width":"100%"},
                  alt: "Image of VCC building with the main entrance",
                  src: require('@/'+_ctx.t('vccimg')+'.jpg')
                }, null, 8, _hoisted_1),
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.location }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('campusvccbuilding')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_grid, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_col, {
                              size: "12",
                              "size-sm": ""
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("a", _hoisted_2, [
                                  _createElementVNode("img", {
                                    alt: "A map of the University of Konstanz campus showing the way to the VCC building from various spots.",
                                    src: require('@/'+_ctx.t('mapimg')+'.jpg')
                                  }, null, 8, _hoisted_3)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_col, {
                              size: "12",
                              "size-sm": "",
                              class: "vcc"
                            }, {
                              default: _withCtx(() => [
                                _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Map Links", -1)),
                                _cache[6] || (_cache[6] = _createElementVNode("p", null, [
                                  _createElementVNode("a", { href: "https://www.openstreetmap.org/#map=19/47.6916/9.18709" }, "OpenStreetMap"),
                                  _createTextVNode(" - "),
                                  _createElementVNode("a", { href: "https://goo.gl/maps/rUSFgNiRDW8Sbg8K7" }, "Google Maps"),
                                  _createTextVNode(" - "),
                                  _createElementVNode("a", { href: "http://maps.apple.com/?ll=47.6916,9.18709" }, "Apple Maps"),
                                  _createTextVNode(" - "),
                                  _createElementVNode("a", { href: "https://www.bing.com/maps?osid=5e849fab-fab6-401a-a067-14c9fe234cc6&cp=47.691654~9.186388&lvl=19&v=2&sV=2&form=S00027" }, "Microsoft Bing Maps")
                                ], -1)),
                                _createElementVNode("h2", null, _toDisplayString(_ctx.t('campusbycar')), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('campuscartext')), 1),
                                _createElementVNode("h2", null, _toDisplayString(_ctx.t('campusbybus')), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('campusbybustext')), 1),
                                _createElementVNode("h3", null, _toDisplayString(_ctx.t('campusline9')), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('campusline9text')), 1),
                                _createElementVNode("h3", null, _toDisplayString(_ctx.t('campusline11')), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('campusline11text')), 1),
                                _createElementVNode("h3", null, _toDisplayString(_ctx.t('campusline13')), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('campusline13text')), 1),
                                _createElementVNode("h2", null, _toDisplayString(_ctx.t('campusmainentrance')), 1),
                                _createElementVNode("p", {
                                  innerHTML: _ctx.t('campusmainentrancetext')
                                }, null, 8, _hoisted_4),
                                _createElementVNode("h2", null, _toDisplayString(_ctx.t('campusdatatheatre')), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('campusdatatheatretext')), 1),
                                _createElementVNode("h2", null, _toDisplayString(_ctx.t('campusoffices')), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.t('campusofficestext')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, { id: "plane" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.airplane }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('planetitle')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, { class: "vcc" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('planezurich')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('planezurichtext')
                    }, null, 8, _hoisted_5),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('trainlinks')
                    }, null, 8, _hoisted_6),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('planestuttgart')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('planestuttgarttext')
                    }, null, 8, _hoisted_7),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('planefriedrichshafen')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('planefriedrichshafentext')
                    }, null, 8, _hoisted_8)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, { id: "train" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.train }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('traintitle')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, { class: "vcc" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.t('trainintro')), 1),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('trainseehas')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.t('trainseehastext')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('trainlinks')
                    }, null, 8, _hoisted_9)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, { id: "bus" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.bus }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('bustitle')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, { class: "vcc" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('buscity')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('buscitytext')
                    }, null, 8, _hoisted_10),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('busregional')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('busregionaltext')
                    }, null, 8, _hoisted_11)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, { id: "ship" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.boat }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('shiptitle')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, { class: "vcc" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('shipmeersburg')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('shipmeersburgtext')
                    }, null, 8, _hoisted_12),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('shipueberlingen')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('shipueberlingentext')
                    }, null, 8, _hoisted_13),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('shipfriedrichshafen')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('shipfriedrichshafentext')
                    }, null, 8, _hoisted_14)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, { id: "car" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.car }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('cartitle')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, { class: "vcc" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('carnorth')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.t('carnorthtext')), 1),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('careast')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.t('careasttext')), 1),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('carsouth')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.t('carsouthtext')), 1),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('carcity')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.t('carcitytext')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, { id: "hotels" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.bed }, null, 8, ["icon"]),
                        _cache[7] || (_cache[7] = _createTextVNode(" Hotels"))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, { class: "vcc" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.t('hotelsintro')), 1),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('hotelsibis')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('hotelsibistext')
                    }, null, 8, _hoisted_16),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('hotelscity')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('hotelscitytext')
                    }, null, 8, _hoisted_17),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('hotelsbb')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('hotelsbbtext')
                    }, null, 8, _hoisted_18),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('hotelsukon')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('hotelsukontext')
                    }, null, 8, _hoisted_19)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, { id: "misc" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.help }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('misctitle')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, { class: "vcc" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.t('miscintro')), 1),
                    _createElementVNode("h2", null, _toDisplayString(_ctx.t('miscthings')), 1),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('miscthings1')
                    }, null, 8, _hoisted_21),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('miscthings2')
                    }, null, 8, _hoisted_22),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('miscthings3')
                    }, null, 8, _hoisted_23),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('miscthings4')
                    }, null, 8, _hoisted_24)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, { id: "contact" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.call }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('contacttitle')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, { class: "vcc" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('contactkuhr')
                    }, null, 8, _hoisted_25),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('contactwebsite')
                    }, null, 8, _hoisted_26),
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('contacttwitter')
                    }, null, 8, _hoisted_27)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_content, { class: "vcc" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      innerHTML: _ctx.t('imprint')
                    }, null, 8, _hoisted_28)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512),
        (_ctx.showScrollTop)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "back-to-top-btn",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.scrollToTop()))
            }, [
              _createVNode(_component_ion_icon, { icon: _ctx.chevronUp }, null, 8, ["icon"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}