<template>
<ion-menu side="start" menu-id="nav" content-id="main">
      <ion-header>
        <ion-toolbar>
          <ion-title>Navigation</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item v-for="n in navs" :key="n.goto" class="nav-item" @click="scrollToEl(n.goto)">
            <ion-icon :icon="n.icon" slot="start"></ion-icon>
            {{ t(n.text) }}
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

  <ion-page>
  
    <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start" v-if="screen.width < breakpoint">
            <ion-button @click="openMenu()">
              <ion-icon slot="start" :icon="menu"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-title class="title" @click="scrollToTop()">{{ t('maintitle') }}</ion-title>

          <ion-buttons slot="primary" v-if="screen.width >= breakpoint">
            <template v-for="n in navs" :key="n.goto">
              <ion-button @click="scrollToEl(n.goto)">
                {{ t(n.text) }}
                <ion-icon slot="start" :icon="n.icon"></ion-icon>
              </ion-button>
            </template>
          </ion-buttons>

          <ion-buttons slot="end">
            <language-switcher></language-switcher>
          </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <ion-content id="main" ref="content" :fullpage="true" :scroll-events="true" v-on:ion-scroll="getScrollPos($event.detail.scrollTop)">
      <ion-card id="vcc">
            <img style="width:100%;" alt="Image of VCC building with the main entrance" :src="require('@/'+t('vccimg')+'.jpg')" />
            <ion-card-header>
              <ion-card-title>
                <ion-icon :icon="location"></ion-icon> {{ t('campusvccbuilding') }}</ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <ion-grid>
                <ion-row>
                  <ion-col size="12" size-sm>
                    <a href="https://www.openstreetmap.org/#map=19/47.6916/9.18709">
                      <img alt="A map of the University of Konstanz campus showing the way to the VCC building from various spots." :src="require('@/'+t('mapimg')+'.jpg')" />
                    </a>
                  </ion-col>

                  <ion-col size="12" size-sm class="vcc">
                    <h2>Map Links</h2>
                    <p>
                      <a href="https://www.openstreetmap.org/#map=19/47.6916/9.18709">OpenStreetMap</a> - 
                      <a href="https://goo.gl/maps/rUSFgNiRDW8Sbg8K7">Google Maps</a> - 
                      <a href="http://maps.apple.com/?ll=47.6916,9.18709">Apple Maps</a> - 
                      <a href="https://www.bing.com/maps?osid=5e849fab-fab6-401a-a067-14c9fe234cc6&cp=47.691654~9.186388&lvl=19&v=2&sV=2&form=S00027">Microsoft Bing Maps</a>
                    </p>


                    <h2>{{ t('campusbycar') }}</h2>
                    <p>{{ t('campuscartext') }}</p>

                    <h2>{{ t('campusbybus') }}</h2>
                    <p>{{ t('campusbybustext') }}</p>


                    <h3>{{ t('campusline9') }}</h3>
                    <p>{{ t('campusline9text') }}</p>

                    <h3>{{ t('campusline11') }}</h3>
                    <p>{{ t('campusline11text') }}</p>

                    <h3>{{ t('campusline13') }}</h3>
                    <p>{{ t('campusline13text') }}</p>

                    <h2>{{ t('campusmainentrance') }}</h2>
                    <p v-html="t('campusmainentrancetext')"></p>

                    <h2>{{ t('campusdatatheatre') }}</h2>
                    <p>{{ t('campusdatatheatretext') }}</p>

                    <h2>{{ t('campusoffices') }}</h2>
                    <p>{{ t('campusofficestext') }}</p>

                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>


          <ion-card id="plane">
            <ion-card-header>
              <ion-card-title>
                <ion-icon :icon="airplane"></ion-icon> {{ t('planetitle') }}</ion-card-title>
            </ion-card-header>

            <ion-card-content class="vcc">
              <h2>{{ t('planezurich') }}</h2>
              <p v-html="t('planezurichtext')"></p>

              <p v-html="t('trainlinks')"></p>

              <h2>{{ t('planestuttgart') }}</h2>
              <p v-html="t('planestuttgarttext')"></p>

              <h2>{{ t('planefriedrichshafen') }}</h2>
              <p v-html="t('planefriedrichshafentext')"></p>
            </ion-card-content>
          </ion-card>


          <ion-card id="train">
            <ion-card-header>
              <ion-card-title>
                <ion-icon :icon="train"></ion-icon> {{ t('traintitle') }}</ion-card-title>
            </ion-card-header>

            <ion-card-content class="vcc">
              <p>{{ t('trainintro') }}
              </p>

              <h2>{{ t('trainseehas') }}</h2>
              <p>{{ t('trainseehastext') }}</p>

              <p v-html="t('trainlinks')"></p>
            </ion-card-content>
          </ion-card>

          <ion-card id="bus">
            <ion-card-header>
              <ion-card-title>
                <ion-icon :icon="bus"></ion-icon> {{ t('bustitle') }}</ion-card-title>
            </ion-card-header>

            <ion-card-content class="vcc">
              <h2>{{ t('buscity') }}</h2>
              <p v-html="t('buscitytext')"></p>

              <h2>{{ t('busregional') }}</h2>
              <p v-html="t('busregionaltext')"></p>
            </ion-card-content>
          </ion-card>

          <ion-card id="ship">
            <ion-card-header>
              <ion-card-title>
                <ion-icon :icon="boat"></ion-icon> {{ t('shiptitle') }}</ion-card-title>
            </ion-card-header>

            <ion-card-content class="vcc">
              <h2>{{ t('shipmeersburg') }}</h2>
              <p v-html="t('shipmeersburgtext')"></p>

              <h2>{{ t('shipueberlingen') }}</h2>
              <p v-html="t('shipueberlingentext')"></p>

              <h2>{{ t('shipfriedrichshafen') }}</h2>
              <p v-html="t('shipfriedrichshafentext')"></p>

            </ion-card-content>
          </ion-card>

          <ion-card id="car">
            <ion-card-header>
              <ion-card-title>
                <ion-icon :icon="car"></ion-icon> {{ t('cartitle') }}</ion-card-title>
            </ion-card-header>

            <ion-card-content class="vcc">
              <h2>{{ t('carnorth') }}</h2>
              <p>{{ t('carnorthtext') }}</p>

              <h2>{{ t('careast') }}</h2>
              <p>{{ t('careasttext') }}</p>

              <h2>{{ t('carsouth') }}</h2>
              <p>{{ t('carsouthtext') }}</p>

              <h2>{{ t('carcity') }}</h2>
              <p>{{ t('carcitytext') }}</p>

            </ion-card-content>
          </ion-card>

          <ion-card id="hotels">
            <ion-card-header>
              <ion-card-title>
                <ion-icon :icon="bed"></ion-icon> Hotels</ion-card-title>
            </ion-card-header>

            <ion-card-content class="vcc">
              <p style="font-weight:bold;">{{ t('hotelsintro') }}</p>


              <h2>{{ t('hotelsibis') }}</h2>
              <p v-html="t('hotelsibistext')"></p>

              <h2>{{ t('hotelscity') }}</h2>
              <p v-html="t('hotelscitytext')"></p>

              <h2>{{ t('hotelsbb') }}</h2>
              <p v-html="t('hotelsbbtext')"></p>

              <h2>{{ t('hotelsukon') }}</h2>
              <p v-html="t('hotelsukontext')"></p>

            </ion-card-content>
          </ion-card>

          <ion-card id="misc">
            <ion-card-header>
              <ion-card-title>
                <ion-icon :icon="help"></ion-icon> {{ t('misctitle') }}</ion-card-title>
            </ion-card-header>

            <ion-card-content class="vcc">
              <p style="font-weight:bold;">{{ t('miscintro') }}</p>

              <h2>{{ t('miscthings') }}</h2>
              <p v-html="t('miscthings1')"></p>

              <p v-html="t('miscthings2')"></p>

              <p v-html="t('miscthings3')"></p>

              <p v-html="t('miscthings4')"></p>

            </ion-card-content>
          </ion-card>

          <ion-card id="contact">
            <ion-card-header>
              <ion-card-title>
                <ion-icon :icon="call"></ion-icon> {{ t('contacttitle') }}</ion-card-title>
            </ion-card-header>

            <ion-card-content class="vcc">
              <p v-html="t('contactkuhr')"></p>

              <p v-html="t('contactwebsite')"></p>

              <p v-html="t('contacttwitter')"></p>

            </ion-card-content>
          </ion-card>

          <ion-card>

            <ion-card-content class="vcc">
              <p v-html="t('imprint')"></p>
            </ion-card-content>
          </ion-card>
    </ion-content>


  <div class="back-to-top-btn" v-if="showScrollTop" @click="scrollToTop()">
    <ion-icon :icon="chevronUp"></ion-icon>
  </div>
    
  </ion-page>
</template>

<script lang="ts">
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonList, IonMenu, IonPage, IonRow, IonTitle, IonToolbar, menuController } from '@ionic/vue';
import { chevronUp, airplane, train, bus, boat, location, car, bed, help, menu, call } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useScreen } from 'vue-screen'

export default defineComponent({
  name: 'HomePage',
  components: {
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent, IonCardHeader, IonCardTitle, IonIcon,
    IonButton,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonItem,
    IonMenu,
    IonList,
    LanguageSwitcher
  },
  setup() {
    const { t } = useI18n();
    const screen = useScreen();
    const breakpoint = 1480;

    const content = ref();
    const scrollToEl = (id: string) => {
      const titleELe = document.getElementById(id);
      if (!titleELe) {
        return;
      }
      menuController.close('nav');
      content.value.$el.scrollToPoint(0, titleELe.offsetTop, 1000);
    };

    const scrollToTop = () => {
      content.value.$el.scrollToTop(1000);
    }

    const showScrollTop = ref(false);

    const getScrollPos = (pos: number) => {
      showScrollTop.value = pos > 100;
    };

    menuController.enable(true, 'nav');
    const openMenu = () => {
      menuController.open('nav');
    }

    const navs = ref([
      {
        icon: location,
        text: 'vccbuilding',
        goto: 'vcc'
      },
      {
        icon: airplane,
        text: 'byplane',
        goto: 'plane'
      },
      {
        icon: train,
        text: 'bytrain',
        goto: 'train'
      },
      {
        icon: bus,
        text: 'bybus',
        goto: 'bus'
      },
      {
        icon: boat,
        text: 'byship',
        goto: 'ship'
      },
      {
        icon: car,
        text: 'bycar',
        goto: 'car'
      },
      {
        icon: bed,
        text: 'hotels',
        goto: 'hotels'
      },
      {
        icon: help,
        text: 'misc',
        goto: 'misc'
      },
      {
        icon: call,
        text: 'contact',
        goto: 'contact'
      }
    ]);

    return {
      t,
      airplane,
      train,
      bus,
      boat,
      location,
      car,
      bed,
      content,
      scrollToEl,
      scrollToTop,
      chevronUp,
      getScrollPos,
      showScrollTop,
      help,
      openMenu,
      menu,
      navs,
      screen,
      breakpoint,
      call
    }
  }
});
</script>

<style scoped>
.vcc h2, .vcc h3 {
  margin-top: 15px;
}

.vcc h2:first-child  {
  margin-top: auto;
}

.back-to-top-btn {
  cursor: pointer;
    background: rgba(158, 158, 158, 0.5);
    position: fixed;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
    
    right: 10px;
    transform: translateX(-50%);
    bottom: 35px;
    z-index: 999;
}

.nav-item {
  cursor:pointer;
}

.nav-item:hover {
  --ion-background-color: #d6d6d6 !important;
}
</style>
