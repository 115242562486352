<template>
  <ion-page>
    <ion-content>
      <div style="padding:3em;padding-bottom:0px;">
        <language-switcher></language-switcher>
      </div>
      <div class="mycontent" v-html="t('imprinttext')"></div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

export default defineComponent({
  name: 'ImprintPage',
  components: {
    IonPage,
    LanguageSwitcher
  },
  setup() {
    const { t } = useI18n();
    

    return {
      t,
    }
  }
});
</script>

<style scoped>
.mycontent {
  padding: 3em;
}
</style>
